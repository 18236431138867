export const getSitemapData = `
  query GetSitemapData(
  $locale: SiteLocale 
  $first: IntType
  ) {
    allProjects(locale: $locale, first: $first) {
      id
      slug
      _allSlugLocales {
        value
        locale
      }
    }
    allTechnologies(locale: $locale, first: $first) {
      id
      title
      _allSlugLocales {
        value
        locale
      }
    }
    allExpertises(locale: $locale, first: $first) {
      id
      title
      _allSlugLocales {
        value
        locale
      }
    }
    allArticles(locale: $locale, first: $first) {
    id 
    title
    _allSlugLocales {
      value
      locale
      }
    }  
  }
`
