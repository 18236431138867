import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const getStudioPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetStudioPage($locale: SiteLocale) {
    studioPage(locale: $locale) {
      title
      subtitle
      seo {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
      body {
        __typename
        ... on ImageVideoBlockRecord {
          id
          image {
            id
            responsiveImage(
              imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
            ) {
              ...ResponsiveImageFragment
            }
          }
          linkVideo
          linkVideoPreview
        }
        ... on TwoColumnsGridTextRecord {
          id
          title
          text
        }
        ... on TextBlockRecord {
          id
          text
        }
        ... on AnimatedAccordionRecord {
          id
          accordionsList {
            id
            text
            title
            image {
              id
              responsiveImage(
                imgixParams: { auto: format, q: "60", fit: crop }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on StudioTeamBlockRecord {
          id
          text
          title
          videoUrl
          image {
            url
            alt
          }
        }
        ... on ImagesGridRecord {
          id
          imagesList {
            alt
            url
          }
        }
        ... on ChosenBlockRecord {
          id
          title
          showLogosDefault
          imagesList {
            alt
            url
          }
        }
        ... on AwardsListRecord {
          id
          title
          awards {
            id
            certificator
            brands
            title
            year
            image {
              url
            }
            linkUrl
          }
        }
        ... on BannerTextMediaBlockRecord {
          id
          textBlock
          backgroundVideoLink
          backgroundImage {
            alt
            responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
              ...ResponsiveImageFragment
            }
          }
        }
      }
    }
  }
`
