
// @ts-nocheck


export const localeCodes =  [
  "it",
  "en"
]

export const localeLoaders = {
  "it": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config",
  "locales": [
    {
      "code": "it",
      "site": "it",
      "iso": "it-IT",
      "name": "Italiano"
    },
    {
      "code": "en",
      "site": "en",
      "iso": "en",
      "name": "English"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.bluemotion3d.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "projects/index": {
      "it": "/works-portfolio-progetti-3d",
      "en": "/works-portfolio-progetti-3d"
    },
    "projects/[slug]/index": {
      "it": "/works-portfolio-progetti-3d/[slug]",
      "en": "/works-portfolio-progetti-3d/[slug]"
    },
    "technology/index": {
      "it": "/technology-3d",
      "en": "/technology-3d"
    },
    "technology/[slug]/index": {
      "it": "/technology-3d/[slug]",
      "en": "/technology-3d/[slug]"
    },
    "careers/index": {
      "it": "/lavora-con-noi",
      "en": "/careers"
    },
    "careers/[slug]/index": {
      "it": "/lavora-con-noi/[slug]",
      "en": "/careers/[slug]"
    },
    "contacts": {
      "it": "/contatti",
      "en": "/contacts"
    },
    "studio": {
      "it": "/studio",
      "en": "/studio"
    },
    "richiedi-progetto": {
      "it": "/contatti/richiedi-un-progetto",
      "en": "/contacts/request-project"
    },
    "are-you-an-agency": {
      "it": "/collaborazioni-tra-agenzie",
      "en": "/are-you-an-agency"
    },
    "form-submission-success": {
      "it": "/invio-modulo-completato",
      "en": "/form-submission-success"
    },
    "form-submission-failure": {
      "it": "/invio-modulo-fallito",
      "en": "/form-submission-failure"
    },
    "newsletter-submission-success": {
      "it": "/iscrizione-newsletter-completata",
      "en": "/newsletter-subscription-success"
    },
    "privacy-policy": {
      "it": "/privacy-policy",
      "en": "/privacy-policy"
    },
    "expertise/index": {
      "it": "/expertise-3d",
      "en": "/expertise-3d"
    },
    "expertise/[slug]/index": {
      "it": "/expertise-3d/[slug]",
      "en": "/expertise-3d/[slug]"
    },
    "blog/index": {
      "it": "/blog",
      "en": "/blog"
    },
    "blog/[slug]/index": {
      "it": "/blog/[slug]",
      "en": "/blog/[slug]"
    },
    "lets-talk/index": {
      "it": "/lets-talk-experience",
      "en": "/lets-talk-experience"
    },
    "index": {
      "it": "/",
      "en": "/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "it",
    "site": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "files": []
  },
  {
    "code": "en",
    "site": "en",
    "iso": "en",
    "name": "English",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
