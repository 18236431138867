<template>
  <div>
   
    <NuxtLayout name="error">

<div>
   <NavigationMenu :external="true"/>
   
     
      <div class="page page-error p-top-xxxl-1 p-bottom-xxxl-1 is-text-center">
        <h1 class="title-l-1 m-bottom-1">
          {{
            error.statusCode === 500
              ? $t('errors.500.title')
              : $t('errors.404.title')
          }}
        </h1>


        <div class="page-error__content m-top-m-1">
          <template v-if="error.statusCode === 500">
        <div
          class="text-1 m-bottom-1"
          v-html="$t('errors.500.text')
             
          "
        />
        </template>
        <template v-else>
          <p class="text-1 m-bottom-1">Sembra che la pagina che stavi cercando non esista più o sia stata spostata.<br>
Non preoccuparti, ti aiutiamo a ritrovare la strada.</p>
        </template>
<p class="text-1 m-bottom-1">Ecco alcune cose che puoi fare:<br>
<ul class="m-top-32px">
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'index' })" external class="link-underline-1"><strong>Torna alla Homepage</strong></NuxtLink>: inizia da capo e scopri tutte le sezioni del nostro sito.
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'contacts' })" external class="link-underline-1"><strong>Contattaci</strong></NuxtLink>: se hai bisogno di assistenza, non esitare a contattarci.
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'expertise' })" external class="link-underline-1"><strong>Scopri le nostre Expertise</strong></NuxtLink>: il mondo 3D può rivoluzionare la tua comunicazione
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'blog' })" external class="link-underline-1"><strong>Leggi i nostri articoli</strong></NuxtLink>: novità e curiosità dal mondo delle 3 dimensioni
  </li>
  <li class="m-top-24px m-bottom-24px">
    <NuxtLink :to="localePath({ name: 'studio' })" external class="link-underline-1"><strong>Lo studio</strong></NuxtLink>: scopri chi siamo e la nostra filosofia
  </li>
</ul>
</p>
        </div>
       <!-- <NuxtLink
          :to="localePath({ name: 'index' })"
          class="button-round-1 is-big is-blue"
          external
          ><span>{{ $t('cta.backToHome') }}</span></NuxtLink
        >-->
      </div>
       <FooterMain :external="true"/>
        <FooterMedical/>
       </div>
    </NuxtLayout>
    <div class="preloader-curtain" data-animate="preloader-curtain"></div>
  </div>
</template>

<script setup>
import pageTransition from '@/assets/js/transitions/helper.js'

definePageMeta({
  pageTransition,
})

const fromError = setFromError()

const props = defineProps({
  error: Object,
})
console.error(props.error)

onMounted(() => {
  console.log(fromError.value)
})

onUnmounted(() => {
  fromError.value = true
  console.log(fromError.value)
})

// Inizializzo lo scroller e lo inietto nella app ion modo da averlo sempre accessibile
const { lenis } = useLenis()
provide('lenis', lenis)
</script>
