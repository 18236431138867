import { default as are_45you_45an_45agencyDKOlJF2RdSMeta } from "/opt/build/repo/pages/are-you-an-agency.vue?macro=true";
import { default as indexx3sznPgcaVMeta } from "/opt/build/repo/pages/blog/[slug]/index.vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as indexXg4YC5iHhmMeta } from "/opt/build/repo/pages/careers/[slug]/index.vue?macro=true";
import { default as indexxwcF5y3IQqMeta } from "/opt/build/repo/pages/careers/index.vue?macro=true";
import { default as contactsQ2Ks7vPefgMeta } from "/opt/build/repo/pages/contacts.vue?macro=true";
import { default as cookie_45policyZcRIsfqfIvMeta } from "/opt/build/repo/pages/cookie-policy.vue?macro=true";
import { default as indexQVKfM23LXdMeta } from "/opt/build/repo/pages/expertise/[slug]/index.vue?macro=true";
import { default as indexuZ1vNkriiyMeta } from "/opt/build/repo/pages/expertise/index.vue?macro=true";
import { default as form_45submission_45failureddjxJla3GJMeta } from "/opt/build/repo/pages/form-submission-failure.vue?macro=true";
import { default as form_45submission_45successk0ay3CQ3szMeta } from "/opt/build/repo/pages/form-submission-success.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexlrvF1FAyXbMeta } from "/opt/build/repo/pages/lets-talk/index.vue?macro=true";
import { default as newsletter_45submission_45successlNdcLQOt6WMeta } from "/opt/build/repo/pages/newsletter-submission-success.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as indexBmiOq17LApMeta } from "/opt/build/repo/pages/projects/[slug]/index.vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as richiedi_45progetto1Nn9GbgCcAMeta } from "/opt/build/repo/pages/richiedi-progetto.vue?macro=true";
import { default as studioHzbk7S3svdMeta } from "/opt/build/repo/pages/studio.vue?macro=true";
import { default as indexuoDA5dcWGeMeta } from "/opt/build/repo/pages/technology/[slug]/index.vue?macro=true";
import { default as indexicFcACNNRXMeta } from "/opt/build/repo/pages/technology/index.vue?macro=true";
import { default as component_45stubCFKGqkTStaMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubCFKGqkTSta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.11_eslint@8.57.0_rollup@4.18.1_sass@1.77.8_stylelint@14.16.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "are-you-an-agency___it",
    path: "/it/collaborazioni-tra-agenzie",
    meta: are_45you_45an_45agencyDKOlJF2RdSMeta || {},
    component: () => import("/opt/build/repo/pages/are-you-an-agency.vue").then(m => m.default || m)
  },
  {
    name: "are-you-an-agency___en",
    path: "/are-you-an-agency",
    meta: are_45you_45an_45agencyDKOlJF2RdSMeta || {},
    component: () => import("/opt/build/repo/pages/are-you-an-agency.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug()",
    meta: indexx3sznPgcaVMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug()",
    meta: indexx3sznPgcaVMeta || {},
    component: () => import("/opt/build/repo/pages/blog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___it",
    path: "/it/lavora-con-noi/:slug()",
    meta: indexXg4YC5iHhmMeta || {},
    component: () => import("/opt/build/repo/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers-slug___en",
    path: "/careers/:slug()",
    meta: indexXg4YC5iHhmMeta || {},
    component: () => import("/opt/build/repo/pages/careers/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___it",
    path: "/it/lavora-con-noi",
    meta: indexxwcF5y3IQqMeta || {},
    component: () => import("/opt/build/repo/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/careers",
    meta: indexxwcF5y3IQqMeta || {},
    component: () => import("/opt/build/repo/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts___it",
    path: "/it/contatti",
    meta: contactsQ2Ks7vPefgMeta || {},
    component: () => import("/opt/build/repo/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en",
    path: "/contacts",
    meta: contactsQ2Ks7vPefgMeta || {},
    component: () => import("/opt/build/repo/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___it",
    path: "/it/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "cookie-policy___en",
    path: "/cookie-policy",
    meta: cookie_45policyZcRIsfqfIvMeta || {},
    component: () => import("/opt/build/repo/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "expertise-slug___it",
    path: "/it/expertise-3d/:slug()",
    meta: indexQVKfM23LXdMeta || {},
    component: () => import("/opt/build/repo/pages/expertise/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "expertise-slug___en",
    path: "/expertise-3d/:slug()",
    meta: indexQVKfM23LXdMeta || {},
    component: () => import("/opt/build/repo/pages/expertise/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "expertise___it",
    path: "/it/expertise-3d",
    meta: indexuZ1vNkriiyMeta || {},
    component: () => import("/opt/build/repo/pages/expertise/index.vue").then(m => m.default || m)
  },
  {
    name: "expertise___en",
    path: "/expertise-3d",
    meta: indexuZ1vNkriiyMeta || {},
    component: () => import("/opt/build/repo/pages/expertise/index.vue").then(m => m.default || m)
  },
  {
    name: "form-submission-failure___it",
    path: "/it/invio-modulo-fallito",
    meta: form_45submission_45failureddjxJla3GJMeta || {},
    component: () => import("/opt/build/repo/pages/form-submission-failure.vue").then(m => m.default || m)
  },
  {
    name: "form-submission-failure___en",
    path: "/form-submission-failure",
    meta: form_45submission_45failureddjxJla3GJMeta || {},
    component: () => import("/opt/build/repo/pages/form-submission-failure.vue").then(m => m.default || m)
  },
  {
    name: "form-submission-success___it",
    path: "/it/invio-modulo-completato",
    meta: form_45submission_45successk0ay3CQ3szMeta || {},
    component: () => import("/opt/build/repo/pages/form-submission-success.vue").then(m => m.default || m)
  },
  {
    name: "form-submission-success___en",
    path: "/form-submission-success",
    meta: form_45submission_45successk0ay3CQ3szMeta || {},
    component: () => import("/opt/build/repo/pages/form-submission-success.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lets-talk___it",
    path: "/it/lets-talk-experience",
    meta: indexlrvF1FAyXbMeta || {},
    component: () => import("/opt/build/repo/pages/lets-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "lets-talk___en",
    path: "/lets-talk-experience",
    meta: indexlrvF1FAyXbMeta || {},
    component: () => import("/opt/build/repo/pages/lets-talk/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-submission-success___it",
    path: "/it/iscrizione-newsletter-completata",
    meta: newsletter_45submission_45successlNdcLQOt6WMeta || {},
    component: () => import("/opt/build/repo/pages/newsletter-submission-success.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-submission-success___en",
    path: "/newsletter-subscription-success",
    meta: newsletter_45submission_45successlNdcLQOt6WMeta || {},
    component: () => import("/opt/build/repo/pages/newsletter-submission-success.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___it",
    path: "/it/works-portfolio-progetti-3d/:slug()",
    meta: indexBmiOq17LApMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___en",
    path: "/works-portfolio-progetti-3d/:slug()",
    meta: indexBmiOq17LApMeta || {},
    component: () => import("/opt/build/repo/pages/projects/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___it",
    path: "/it/works-portfolio-progetti-3d",
    meta: indexxtMqaql9AYMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___en",
    path: "/works-portfolio-progetti-3d",
    meta: indexxtMqaql9AYMeta || {},
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "richiedi-progetto___it",
    path: "/it/contatti/richiedi-un-progetto",
    meta: richiedi_45progetto1Nn9GbgCcAMeta || {},
    component: () => import("/opt/build/repo/pages/richiedi-progetto.vue").then(m => m.default || m)
  },
  {
    name: "richiedi-progetto___en",
    path: "/contacts/request-project",
    meta: richiedi_45progetto1Nn9GbgCcAMeta || {},
    component: () => import("/opt/build/repo/pages/richiedi-progetto.vue").then(m => m.default || m)
  },
  {
    name: "studio___it",
    path: "/it/studio",
    meta: studioHzbk7S3svdMeta || {},
    component: () => import("/opt/build/repo/pages/studio.vue").then(m => m.default || m)
  },
  {
    name: "studio___en",
    path: "/studio",
    meta: studioHzbk7S3svdMeta || {},
    component: () => import("/opt/build/repo/pages/studio.vue").then(m => m.default || m)
  },
  {
    name: "technology-slug___it",
    path: "/it/technology-3d/:slug()",
    meta: indexuoDA5dcWGeMeta || {},
    component: () => import("/opt/build/repo/pages/technology/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "technology-slug___en",
    path: "/technology-3d/:slug()",
    meta: indexuoDA5dcWGeMeta || {},
    component: () => import("/opt/build/repo/pages/technology/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "technology___it",
    path: "/it/technology-3d",
    meta: indexicFcACNNRXMeta || {},
    component: () => import("/opt/build/repo/pages/technology/index.vue").then(m => m.default || m)
  },
  {
    name: "technology___en",
    path: "/technology-3d",
    meta: indexicFcACNNRXMeta || {},
    component: () => import("/opt/build/repo/pages/technology/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubCFKGqkTStaMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubCFKGqkTSta
  }
]