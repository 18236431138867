<template>
  <div
    class="footer-medical-placeholder"
    :style="{ '--height': `${isFixed ? height : 0}px` }"
  />
  <section
    ref="medical"
    class="footer-medical"
    :class="{ '--fixed': height > 0 && isFixed }"
  >
    <div class="row-1 p-top-1 p-bottom-1">
      <div class="footer-medical-container flex is-justify-space-between">
        <div class="footer-medical-title">
          <div v-html="footer?.title" class="title-l-1" />

          <div class="m-top-1 footer-medical-title__text">
            <div
              v-html="footer?.text"
              class="text-1 show-for-large m-bottom-1"
            />
            <div class="flex">
              <a
                target="_blank"
                rel="noopener"
                :href="footer?.ctaLink"
                class="button-round-1 is-transparent"
              >
                <span>{{ footer?.ctaLabel }}</span>
              </a>
            </div>
          </div>
        </div>
        <client-only>
          <div class="footer-medical-block__content is-rounded-wrap">
            <div
              class="footer-medical-block__content__preview"
              data-animate="block-image-preview"
            >
              <WidgetPicture
                class="is-cover"
                :image="{ custom: footer?.image?.responsiveImage }"
                :lazyLoad="true"
                :alt="footer?.image?.responsiveImage?.alt"
              />
            </div>
            <div
              v-if="footer?.videoUrl !== ''"
              class="footer-medical-block__content__video"
              data-animate="footer-block-video-preview"
            >
              <WidgetVideo
                class="is-cover"
                :videoUrl="footer?.videoUrl"
                :loop="true"
                :forcePlay="true"
                :autoplay="true"
                :muted="true"
                :preload="'auto'"
                :playInViewport="true"
                @play="onViewportEnter"
                @pause="onViewportLeave"
              />
            </div>
          </div>
        </client-only>
        <div class="footer-medical-text-block">
          <div v-html="footer?.text" class="text-1 show-for-large" />
          <div class="flex">
            <a
              target="_blank"
              rel="noopener"
              :href="footer?.ctaLink"
              class="button-round-1 is-transparent m-top-1"
            >
              <span>{{ footer?.ctaLabel }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterMedical',
}
</script>

<script setup>
import { gsap } from 'gsap'
import { debounce } from '~/assets/js/utils.js'
import queries from '@/graphql/dato/queries/index.js'

const isFixed = ref(false)
const medical = ref(null)
const height = ref(0)

const { locale } = useI18n()

const query = queries.footer.getMedicalFooter
const variables = computed(() => ({
  locale: locale.value,
}))
const { data } = await useGraphql(query, variables)

const footer = computed(() => data.value?.medicalFooter)
const hasPreview = computed(() => data.value?.medicalFooter.videoUrl)

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  setTimeout(() => {
    onResize()
  }, 500)
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(onResize), false)
})

const onResize = () => {
  if (medical.value) {
    height.value = medical.value.offsetHeight
    isFixed.value = medical.value.offsetHeight < window.innerHeight
  }
}

const video = ref(null)
const play = ref(false)
const duration = 0.6
const ease = 'power2.inOut'

const touch = ref(false)

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints /// !! trasformano 0/1 in Bool
})

watch(
  () => play.value,
  (play) => {
    if (play) {
      video.value.videoElement?.play()
      showCover.value = false
    } else {
      videoElement.value?.pause()
    }
  }
)

const onViewportEnter = () => {
  const parent = medical.value
  const video = parent?.querySelector(
    '[data-animate="footer-block-video-preview"]'
  )

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 1, duration, ease })
  }
}

const onViewportLeave = () => {
  const parent = medical.value
  const video = parent?.querySelector(
    '[data-animate="footer-block-video-preview"]'
  )

  if (hasPreview.value) {
    gsap.to(video, { autoAlpha: 0, duration, ease })
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
